import React from "react";

const projects = [
  {
    name: "GamersHub",
    link: "https://bit.ly/3wZCerL",
  },
];

const PersonalProjects = () => {
  return (
    <>
      <div className="section-header lg:mt-20">
        <div className="section-header-container">
          <div className="section-title">personal projects</div>
          <div className="section-order">/003</div>
        </div>
      </div>

      {/* <div className="divider"></div> */}

      <section className="project">
        <div className="project-container">
          <div>
            {projects.map((project, id) => {
              return (
                <nav
                  className="menu border-t-2 border-gray-300"
                  key={`project-${project.name}-${project - id}`}
                >
                  <div className="menu__item">
                    <a
                      className="menu__item-link"
                      target="_blank"
                      href={project.link}
                    >
                      / {project.name}
                    </a>
                    {project.subtext && (
                      <p className="mt-s10 text-xs">({project.subtext})</p>
                    )}
                    {project.image && (
                      <img
                        className="menu__item-img xs:hidden lg:block"
                        alt="italic-image"
                        height="400"
                        width="200"
                        src={project.image}
                      />
                    )}
                    <div className="marquee">
                      <div className="marquee__inner font-normal font-secondary uppercase">
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                      </div>
                    </div>
                  </div>

                  {/* <div className="divider"></div> */}
                </nav>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default PersonalProjects;

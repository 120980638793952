import React from "react";
import Fade from "react-reveal/Fade";
import Helmet from "react-helmet";
import { ParallaxProvider } from "react-scroll-parallax";

import Navbar from "../components/common/Navbar";
import AboutSection from "../components/AboutSection";
import SocialSection from "../components/SocialSection";
import ClientsSection from "../components/ClientsSection";
import ProjectsSection from "../components/ProjectsSection";
import HomeHeroSection from "../components/HomeHeroSection";
import PersonalProjects from "../components/PersonalProjects";

import { SmoothScroll } from "../../js/SmoothScroll";
import { enterAnimation } from "../animation/loadingAnimation";
import Intro from "../components/common/Intro";

const Index = () => {
  const [loading, setLoading] = React.useState(true);
  const [textAnimation, setTextAnimation] = React.useState(true);

  function init() {
    new SmoothScroll(document, 60, 20);
  }
  const renderLoader = () => {
    setTextAnimation(false);
    enterAnimation();
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  };

  React.useEffect(() => {
    init();

    return () => {
      clearTimeout();
    };
  }, []);

  if (loading) {
    return <Intro renderLoader={renderLoader} textAnimation={textAnimation} />;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mayank Verma</title>
        <meta
          property="description"
          name="description"
          content="I'm Mayank Verma, a web & mobile developer working in Gurgaon, India. I create websites, web apps, mobile apps, retail concepts and everything in-between"
        />

        {/* og tags */}
        <meta property="og:type" name="og:type" content="Mayank Verma" />
        <meta property="og:title" name="og:title" content="Mayank Verma" />
        <meta
          property="og:url"
          name="og:url"
          content="https://mayankverma.org"
        />

        <meta
          property="og:description"
          name="og:description"
          content="I'm Mayank Verma, a web & mobile developer working in Gurgaon, India. I create websites, web apps, mobile apps, retail concepts and everything in-between"
        />

        <meta
          property="og:image"
          name="og:image"
          content="/static/images/favicon.svg"
        />

        <link rel="mayank icon" href="/images/favicon.svg" />
        <link rel="canonical" href="https://mayankverma.org/" />
      </Helmet>

      {!loading && (
        <ParallaxProvider>
          <div>
            <div className="wrapper w-full h-full">
              <div className="outer-container">
                {/* navbar */}
                <Navbar />
                {/* divider */}

                <div className="divider"></div>

                {/* header */}
                <Fade duration={3000}>
                  <HomeHeroSection />
                </Fade>

                <div className="whitespace"></div>

                {/* SECTIONS */}

                {/* ABOUT */}
                <Fade duration={3000}>
                  <AboutSection />
                </Fade>

                <div className="whitespace"></div>

                {/* PROJECTS */}
                <Fade duration={3000}>
                  <ProjectsSection />
                </Fade>

                <div className="whitespace"></div>

                {/* Personel PROJECTS */}
                <Fade duration={3000}>
                  <PersonalProjects />
                </Fade>

                <div className="whitespace"></div>

                {/* <!-- clients --> */}
                {/* <Fade duration={3000}>
                  <div className="lg:mt-s100">
                    <ClientsSection />
                  </div>
                </Fade>
                <div className="whitespace"></div> */}

                {/* <!-- social  --> */}
                <Fade duration={3000}>
                  <SocialSection />
                </Fade>
                {/* <!-- footer --> */}

                {/*  */}
              </div>
            </div>
          </div>
        </ParallaxProvider>
      )}
    </>
  );
};

export default Index;

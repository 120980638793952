import React from "react";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <div className="navbar-container flex justify-center py-s10 text-md">
          <div className="site-title flex-1 whitespace-nowrap">
            Mayank Verma, Gurgaon, IND
          </div>
          <div className="site-type  flex-1 "></div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3 w-3"
              style={{ marginBottom: "2px", marginRight: "2px" }}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            <div className="phone-number flex-1  text-right">+919953589995</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

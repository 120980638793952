import { gsap } from "gsap";
import anime from "animejs/lib/anime.es.js";

export const enterAnimation = () => {
  gsap.to(".btn", 1, {
    opacity: 0,
    y: -60,
    ease: "easeInOut",
  });

  gsap.to(".text-wrapper > div", 1, {
    x: "400",
    ease: "easeInOut",
    delay: 1,
    stagger: 0.1,
  });

  gsap.to(".text-wrapper", 2, {
    y: 1400,
    scale: 2,
    rotate: -90,
    ease: "easeInOut",
    delay: 2,
  });

  gsap.to(".text", 2, {
    opacity: 1,
    ease: "easeInOut",
    delay: 3,
  });

  gsap.to(".text-wrapper > div", 4, {
    x: -6000,
    ease: "easeInOut",
    delay: 3.5,
    stagger: 0.05,
  });

  gsap.to(".text-container", 1, {
    bottom: "-100%",
    ease: "easeInOut",
    delay: 5.5,
  });

  let textWrapper = document.querySelector(".header");
  textWrapper.innerHTML = textWrapper.textContent.replace(
    /\S/g,
    "<span class='letter'>$&</span>"
  );

  anime({
    targets: ".header .letter",
    opacity: [0, 1],
    translateY: [200, 0],
    translateZ: 0,
    easing: "easeOutExpo",
    duration: 2000,
    delay: (el, i) => 7000 + 40 * i,
  });
};

import React from "react";

const socials = [
  {
    name: "github",
    link: "https://github.com/mayankVerma96",
  },
  {
    name: "linkedIn",
    link: "https://www.linkedin.com/in/mayank-verma31/",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/_mayankv3rma/",
  },
  {
    name: "contact me",
    link: "mailto:mayankv8292@gmail.com",
  },
];

const SocialSection = () => {
  return (
    <>
      <div className="section-header">
        <div className="section-header-container">
          <div className="section-title">socials</div>
          <div className="section-order">/004</div>
        </div>
      </div>

      <div className="divider"></div>

      <section className="socials py-s20">
        <div className="socials-container flex justify-between font-secondary xs:text-2xl lg:text-6xl">
          {socials.map((social, id) => {
            return (
              <div key={`social-${social.name}-${id}`}>
                <a
                  className="cursor-pointer"
                  href={social.link}
                  target="_blank"
                >
                  <p className="glow-text" style={{ cursor: "pointer" }}>
                    {social.name}
                  </p>
                </a>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default SocialSection;

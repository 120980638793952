import React from "react";

const AboutSection = () => {
  return (
    <>
      <div className="section-header xs:mt-12 lg:mt-24">
        <div className="section-header-container">
          <div className="section-title">about</div>
          <div className="section-order">/001</div>
        </div>
      </div>

      <div className="divider"></div>

      {/* ABOUT */}
      <section className="about w-full">
        <div className="about-container xs:w-full lg:w-8/12 float-right">
          <p className="my-10 xs:text-2xl lg:text-4xl">
            Hello I'm Mayank. Currently a Front End Developer working in the
            Web3 space at{" "}
            <span className="font-bold text-blue-300">Torum Technology</span>. I
            strive to create interactive experiences and functional interfaces
            using ReactJS, Atomic Architecture and Design Systems
          </p>
          <p className="my-10 xs:text-2xl lg:text-4xl">
            When i'm not working you can find me at the Gym or at a food joint
            in the city.
          </p>
          <p className="xs:text-2xl lg:text-4xl">
            If you fancy a chat feel free to drop me a line.
          </p>
        </div>
      </section>
    </>
  );
};

export default AboutSection;

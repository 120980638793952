import React from "react";
import { Parallax } from "react-scroll-parallax";

const HomeHeroSection = () => {
  return (
    <>
      <div className="header-hero xs:mx-2 xs:mt-10 xs:mb-0 lg:m-20 z-10 relative">
        <div className="header-container">
          <h1 className="text-6xl lg:text-14xl font-secondary font-medium">
            <span>Mayank</span> <span>Verma</span> <br />
            <span>Frontend</span>
            <br />
            &nbsp;<span>Developer</span>
          </h1>
        </div>
      </div>
      {/* hero-image */}
      <div className="hero-image xs:mt-0 lg:-mt-96 w-full -mt-72">
        <div className="hero-image-container xs:w-full lg:w-6/12 float-right">
          <Parallax className="custom-class" y={[50, 0]} tagOuter="figure">
            <div style={{ position: "relative" }}>
              <img
                className="w-full rounded-10 h-96 object-cover"
                src="/images/mayank-two.jpeg"
              />
              <div className="image-overlay" />
            </div>
          </Parallax>
        </div>
      </div>
    </>
  );
};

export default HomeHeroSection;

import React from "react";

const projects = [
  {
    name: "Torum",
    image: "images/torum.png",
    link: "https://www.torum.com/",
  },
  {
    name: "Italic",
    image: "images/italic.png",
    link: "https://www.italic.com",
  },
  {
    name: "NotPot",
    image: "images/notpot.png",
    link: "http://notpot.com",
  },
  {
    name: "Trend",
    image: "images/trend.png",
    link: "https://trend.io",
  },
];

const ProjectsSection = () => {
  return (
    <>
      <div className="section-header">
        <div className="section-header-container">
          <div className="section-title">professional projects</div>
          <div className="section-order">/002</div>
        </div>
      </div>

      {/* <div className="divider"></div> */}

      <section className="project">
        <div className="project-container">
          <div>
            {projects.map((project, id) => {
              return (
                <nav
                  className="menu border-t-2 border-gray-300"
                  key={`project-${project.name}-${project - id}`}
                >
                  <div className="menu__item">
                    <a
                      className="menu__item-link"
                      target="_blank"
                      href={project.link}
                    >
                      / {project.name}
                    </a>
                    {project.subtext && (
                      <p className="mt-s10 text-xs">({project.subtext})</p>
                    )}
                    <img
                      className="menu__item-img xs:hidden lg:block rounded-30"
                      alt="preview"
                      height="400"
                      width="200"
                      src={project.image}
                    />
                    <div className="marquee">
                      <div className="marquee__inner font-normal font-secondary uppercase">
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                        <span>{project.name}</span>
                      </div>
                    </div>
                  </div>

                  {/* <div className="divider"></div> */}
                </nav>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectsSection;

import React from "react";

const Intro = ({ renderLoader, textAnimation }) => {
  return (
    <>
      <p className="btn glow-text" onClick={renderLoader}>
        Say Hi
      </p>
      <div className="text-container"></div>
      <div className="text-wrapper">
        <div className={`${textAnimation && "text-to-left"} text`}>
          Any fool can write code that a computer can understand. Good
          programmers write code that humans can understand. Any fool can write
          code that a computer can understand.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          First, solve the problem. Then, write the code. Code is like humor.
          When you have to explain it, it’s bad. First, solve the problem. Then,
          write the code. Code is like humor.
        </div>

        <div className={`${textAnimation && "text-to-left"} text`}>
          Experience is the name everyone gives to their mistakes. Fix the
          cause, not the symptom. Experience is the name everyone gives to their
          mistakes.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          Sometimes it pays to stay in bed on Monday, rather than spending the
          rest of the week debugging Monday’s code. Java is to JavaScript what
          car is to Carpet.
        </div>

        <div className={`${textAnimation && "text-to-left"} text`}>
          Perfection is achieved not when there is nothing more to add, but
          rather when there is nothing more to take away. Simplicity is the soul
          of efficiency.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          Simplicity is the soul of efficiency. Make it work, make it right,
          make it fast. Simplicity is the soul of efficiency. Make it work, make
          it right, make it fast.
        </div>

        <div className={`${textAnimation && "text-to-left"} text`}>
          Before software can be reusable it first has to be usable. Ruby is
          rubbish! PHP is phpantastic! Before software can be reusable it first
          has to be usable.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          Code is like humor. When you have to explain it, it’s bad. Java is to
          JavaScript what car is to Carpet. Before software can be reusable it
          first has to be usable.
        </div>

        <div className={`${textAnimation && "text-to-left"} text`}>
          Optimism is an occupational hazard of programming: feedback is the
          treatment. Optimism is an occupational hazard of programming: feedback
          is the treatment.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          When to use iterative development? You should use iterative
          development only on projects that you want to succeed.Simplicity is
          the soul of efficiency.
        </div>

        <div className={`${textAnimation && "text-to-left"} text`}>
          Perfection is achieved not when there is nothing more to add, but
          rather when there is nothing more to take away. Simplicity is the soul
          of efficiency.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          When to use iterative development? You should use iterative
          development only on projects that you want to succeed. When to use
          iterative development?
        </div>

        <div className={`${textAnimation && "text-to-left"} text`}>
          In order to be irreplaceable, one must always be different. Before
          software can be reusable it first has to be usable.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          Code is like humor. When you have to explain it, it’s bad. Java is to
          JavaScript what car is to Carpet.Optimism is an occupational hazard of
          programming: feedback is the treatment.
        </div>

        <div className={`${textAnimation && "text-to-left"} text`}>
          Optimism is an occupational hazard of programming: feedback is the
          treatment. Optimism is an occupational hazard of programming: feedback
          is the treatment.
        </div>

        <div className={`${textAnimation && "text-to-right"} text`}>
          Simplicity is the soul of efficiency. Make it work, make it right,
          make it fast.Simplicity is the soul of efficiency. Make it work, make
          it right, make it fast.
        </div>
      </div>
      <div className="header">Hello</div>
    </>
  );
};

export default Intro;
